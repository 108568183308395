<template>
  <div class="row" v-if="Object.keys(purchase_request).length !== 0">
    <purchase-request-header-status :current="current" :purchase_request="purchase_request"></purchase-request-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="font-weight-bold">PR Number</label>
                <p>{{ purchase_request.pr_number }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="font-weight-bold">Delivery On Before</label>
                <p>{{ purchase_request.delivery_date }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">
            <div class="col-md-4 float-sm-right" style="padding-right: 0px !important;">
              <input type="text" v-model="searchQuery" class="form-control form-control mb-3 " placeholder="Type free product name for search..." style="border: 2px solid #00000054 !important;">
            </div>
          </div>
          <form action="">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Qty</th>
                  <th>Unit Price</th>
                  <th>Sub Total</th>
                  <th>Total</th>
                  <th>Free Product</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in resultQuery" :key="index">
                  <td>{{ product.product.name }}</td>
                  <td>{{ product.product.unit }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.unit_price }}</td>
                  <td>{{ currencyConverter(product.sub_total) }}</td>
                  <td>{{ currencyConverter(product.total) }}</td>
                  <td>
                    <a v-if="!user.role.includes('procurement')" href="javascript: void(0);" v-b-modal.add-free-product-modal @click="updateFreePurchaseRequestItems(product.free_purchase_request_items, product.id), $bvModal.show('add-free-product-modal')" class="btn btn-sm btn-info mr-1">
                      <i class="fa fa-plus mr-1"/>Add/Update
                    </a>
                    <a href="javascript: void(0);" v-b-modal.detail-free-product-modal @click="detailFreePurchaseRequestItems(product.free_purchase_request_items), $bvModal.show('detail-free-product-modal')" class="btn btn-sm btn-primary mr-1">
                      <i class="fa fa-info mr-1"/>Detail
                    </a>
                    <a v-if="!user.role.includes('procurement')" href="javascript: void(0);" v-b-modal.hub-transfer-free-product-modal @click="hubTransferFreePurchaseRequestItems(product.id), $bvModal.show('hub-transfer-free-product-modal')" class="btn btn-sm btn-success mr-1">
                      <i class="fa fa-paper-plane mr-1"/>Transfer(Hub)
                    </a>
                  </td>
                </tr>
                <!--  Total, Others, shipping-->
                <tr>
                  <th style="text-align: right" colspan="5">SUBTOTAL</th>
                  <td>{{ currencyConverter(purchase_request.subtotal) }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">OTHER</th>
                  <td>{{ currencyConverter(purchase_request.others) }}</td>
                </tr>
                <tr>
                  <th style="text-align: right" colspan="5">TOTAL</th>
                  <td>{{ currencyConverter(purchase_request.grand_total)}}</td>
                </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
          </form>
        </div>
      </div>
    </div>
    <add-free-product
      :free_purchase_request_items="free_purchase_request_items"
      :purchase_request_item_id="purchase_request_item_id"
      @getPurchaseRequest="getPurchaseRequest">
    </add-free-product>
    <detail-free-product
      :free_purchase_request_items="free_purchase_request_items">
    </detail-free-product>
    <hub-transfer-free-product
      :purchase_request_item_id="purchase_request_item_id"
      :hubs="hubs"
      @getPurchaseRequest="getPurchaseRequest"
      ref="hubTransferFreeProductComponent">
    </hub-transfer-free-product>
  </div>
</template>

<script>

import apiClient from '@/services/axios'
import addFreeProduct from '@/views/freeProduct/purchaseRequest/partial/addFreeProduct'
import detailFreeProduct from '@/views/freeProduct/purchaseRequest/partial/detailFreeProduct'
import hubTransferFreeProduct from '@/views/freeProduct/purchaseRequest/partial/hubTransferFreeProduct'
import purchaseRequestHeaderStatus from '@/views/purchaseRequest/partial/purchase_request_header_status'
import { mapGetters } from 'vuex'

export default {
  name: 'purchaseRequestDetails',
  components: { addFreeProduct, detailFreeProduct, hubTransferFreeProduct, purchaseRequestHeaderStatus },
  data() {
    return {
      products: [],
      hubs: [],
      warehouses: [],
      warehouses_array: [],
      hubs_array: [],
      free_purchase_request_items: [],
      purchase_request_item_id: '',
      purchase_request: {},
      loader: false,
      flag: false,
      loading: false,
      mailLoader: false,
      renderComponent: false,
      current: 0,
      searchQuery: '',
    }
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        const filteredArray = this.products.filter(element => element.free_purchase_request_items
          // eslint-disable-next-line camelcase
          .some(free_purchase_request_item =>
            this.searchQuery.toLowerCase().split(' ').every(v => free_purchase_request_item.name.toLowerCase().includes(v)),
          ),
        )
        return filteredArray
      } else {
        return this.products
      }
    },
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getPurchaseRequest()
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.warehouses_array = data.warehouses
          this.hubs_array = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPurchaseRequest() {
      this.loading = true
      const purchaseRequestId = this.$route.params.purchase_request_id
      apiClient.get('api/purchase-requests/' + purchaseRequestId)
        .then(response => {
          if (!response.data.error) {
            this.loading = false
            const data = response.data
            this.products = data.products
            this.purchase_request = data.purchase_request
            this.current = data.max_purchase_request_status
          } else {
            this.loading = false
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateFreePurchaseRequestItems(freePurchaseRequestItems, purchaseRequestItemId) {
      this.purchase_request_item_id = ''
      this.free_purchase_request_items = []
      this.purchase_request_item_id = purchaseRequestItemId
      this.free_purchase_request_items = freePurchaseRequestItems
    },
    detailFreePurchaseRequestItems(freePurchaseRequestItems) {
      this.free_purchase_request_items = []
      this.free_purchase_request_items = freePurchaseRequestItems
    },
    hubTransferFreePurchaseRequestItems(purchaseRequestItemId) {
      this.hubs = []
      this.purchase_request_item_id = []
      this.hubs = this.hubs_array
      this.purchase_request_item_id = purchaseRequestItemId
    },
    currencyConverter(x) {
      return this.$localCurrency(x)
    },
  },
}
</script>
