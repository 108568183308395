<template>
  <div>
    <b-modal id="add-free-product-modal" title="Add Free Product" size="lg" hide-footer>
      <div class="modal-content bg-white rounded">
        <div class="row">
          <div class="col-md-12 mb-2">
            <button class="btn btn-sm btn-primary pull-right" @click="addRow">
              <i class="fa fa-plus mr-1"></i>Add Row
            </button>
          </div>
        </div>
        <table class="table table-sm table-bordered text-center">
          <thead>
            <tr>
              <th>Free Product Name</th>
              <th>Product Qty</th>
              <th>Inbound Qty</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(free_purchase_request_item, index) in free_purchase_request_items' :key="index">
              <input v-model="free_purchase_request_item.purchase_request_item_id" type="hidden"/>
              <td>
                <input v-model="free_purchase_request_item.name" v-validate="'required'" :name="'name'+index" class="form-control" type="text"/>
                <span class="error invalid-feedback" v-if="errors.has('name'+index)">Free product name is required</span>
              </td>
              <td>
                <input :disabled="free_purchase_request_item.inbounded_quantity == free_purchase_request_item.quantity ? true : false" v-model="free_purchase_request_item.quantity" v-validate="{ required: true, max: 5, regex: /^[0-9]*$/ }"  @change="transferAmount($event.target.value, free_purchase_request_item.inbounded_quantity, free_purchase_request_item.quantity, index)"  :name="'quantity'+index" class="form-control" type="text"/>
                <span class="error invalid-feedback" v-if="errors.has('quantity'+index)">Quantity is required</span>
              </td>
              <td>{{ free_purchase_request_item.inbounded_quantity }}</td>
              <td>
                <button :disabled="checkForRemove(free_purchase_request_item.inbounded_quantity, free_purchase_request_item.quantity) ? true : false" class="btn btn-sm btn-danger" @click="deleteRow(index)" >
                  <i class="fa fa-remove mr-1"></i> Remove Row
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 class="text-center text-secondary" v-if="free_purchase_request_items.length === 0">
          <Empty :image="simpleImage"></Empty>
        </h5>
        <div class="row">
          <div class="col-md-12">
            <a-button v-if="free_purchase_request_items.length > 0" class="btn btn-sm btn-success float-left" :loading="submitLoading"  @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Save Changes</a-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { Empty } from 'ant-design-vue'

export default {
  components: { Empty },
  props: ['free_purchase_request_items', 'purchase_request_item_id'],
  data() {
    return {
      submitLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  methods: {
    addRow: function () {
      this.free_purchase_request_items.push({
        name: '',
        quantity: '',
        purchase_request_item_id: '',
      })
    },
    deleteRow(index) {
      this.free_purchase_request_items.splice(index, 1)
    },
    transferAmount($event, inboundedQty, quantity, index) {
      const transferAmount = $event
      const inboundedQuantity = parseFloat(inboundedQty || 0)
      const total = parseFloat(transferAmount)
      if ((total < inboundedQuantity)) {
        this.$notification.error({
          message: 'Product qty must be greater then to inbound qty',
        })
        this.free_purchase_request_items[index].quantity = ''
      }
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          if (this.free_purchase_request_items.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.submitLoading = true
          apiClient.post('api/free/purchase-request/insert/' + this.purchase_request_item_id, { free_purchase_request_items: this.free_purchase_request_items })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$bvModal.hide('add-free-product-modal')
                this.$notification.success({
                  message: response.data.message,
                })
                this.$emit('getPurchaseRequest')
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.submitLoading = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    checkForRemove(inboundedQuantity, quantity) {
      return parseInt(inboundedQuantity) > parseInt(0)
    },
  },
}
</script>
<style>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
