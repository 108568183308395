<template>
  <div>
    <b-modal id="hub-transfer-free-product-modal" title="Hub Transfer Free Product" size="xl" hide-footer @hidden="refreshComponentData()">
      <div class="modal-content bg-white rounded">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form action="">
                <div class="form-row">
                  <div class="col-md-6">
                    <select v-model="search_data.hub_id" v-validate="{ required: true }" id="hub_id" name="hub_id" class="form-control form-control-sm">
                      <option selected disabled :value="''">Select a hub</option>
                      <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
                    </select>
                    <div class="invalid-feedback">Hub name is required</div>
                  </div>
                  <div class="col-md-6">
                    <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="Object.keys(hub).length !== 0">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-weight-bold">Hub Name</label>
                    <p>{{ hub.name }}</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-weight-bold">Address</label>
                    <p>{{ hub.address }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <form action="">
                <table class="table table-sm table-bordered">
                  <thead>
                  <tr>
                    <th>Free Product Name</th>
                    <th>Total Qty</th>
                    <th>Total Transferred Qty</th>
                    <th style="color: red">Remaining Qty</th>
                    <th>Accept <span style="color: red">| Dispute Amount</span></th>
                    <th>Transfer Qty</th>
                    <th>Comment</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for='(free_purchase_request_item, index) in get_free_purchase_request_items' :key="index">
                    <input v-model="free_purchase_request_item.purchase_request_item_id" type="hidden"/>
                    <td>{{ free_purchase_request_item.name }}</td>
                    <td>{{ free_purchase_request_item.quantity }}</td>
                    <td>{{ free_purchase_request_item.total_inbounded }}</td>
                    <td style="color: red">{{ remainingQuantity(free_purchase_request_item.quantity, free_purchase_request_item.total_inbounded) }}</td>
                    <td>{{ free_purchase_request_item.total_transfer_amount }}<span style="color: red" v-if="free_purchase_request_item.total_dispute_amount > 0">| {{ free_purchase_request_item.total_dispute_amount }}</span></td>
                    <td>
                      <input :disabled="remainingQuantity(free_purchase_request_item.quantity, free_purchase_request_item.total_inbounded) == 0 ? true : false" class="form-control form-control-sm" :id="'transfer_amount_'+free_purchase_request_item.free_purchase_request_item_id"  v-model="get_free_purchase_request_items[index].transfer_amount" @change="newInboundQuantity($event.target.value, index)" v-validate="{ max: 5, regex: /^[0-9]*$/ }" :name="'transfer_amount_' + index"/>
                    </td>
                    <td>
                      <input :disabled="remainingQuantity(free_purchase_request_item.quantity, free_purchase_request_item.total_inbounded) == 0 ? true : false" class="form-control form-control-sm" :id="'transferer_comment'+free_purchase_request_item.free_purchase_request_item_id"  v-model="get_free_purchase_request_items[index].transferer_comment" :name="'transferer_comment' + index"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <a-skeleton active :loading="loading"></a-skeleton>
                <h5 class="text-center text-secondary" v-if="get_free_purchase_request_items.length === 0 || !flag">
                  <Empty :image="simpleImage"></Empty>
                </h5>
                <a-button class="btn btn-sm btn-success float-left" :loading="submitLoading"  @click.prevent="save"><i class="fa fa-paper-plane mr-1" aria-hidden="true"></i>Transfer Product</a-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import $ from 'jquery'
import { Empty } from 'ant-design-vue'

export default {
  components: { Empty },
  props: ['purchase_request_item_id', 'hubs'],
  data() {
    return {
      hub: {},
      get_free_purchase_request_items: {},
      product_id_array: {},
      inboundArr: [],
      btnLoading: false,
      flag: false,
      loading: false,
      submitLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        hub_id: '',
      },
    }
  },
  mounted() {},
  methods: {
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.btnLoading = true
          this.flag = true
          this.loading = true
          this.search_data.purchase_request_item_id = this.purchase_request_item_id
          apiClient.post('api/free/hub/purchase-request/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.get_free_purchase_request_items = response.data.free_purchase_request_items
              this.product_id_array = response.data.product_id_array
              this.hub = response.data.hub
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    remainingQuantity(totalQuantity, totalInbounded) {
      return parseFloat(totalQuantity || 0) - parseFloat(totalInbounded || 0)
    },
    newInboundQuantity($event, index) {
      const quantity = $event
      const totalInbounded = this.get_free_purchase_request_items[index].total_inbounded
      const prQuantity = this.get_free_purchase_request_items[index].quantity
      const newInboundedQuantity = parseInt(totalInbounded) + parseInt(quantity)
      if (newInboundedQuantity > prQuantity) {
        this.$notification.error({
          message: 'Total inbounded quantity is crossing free quantity',
        })
        this.get_free_purchase_request_items[index].transfer_amount = ''
      }
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.inboundArr = []
          this.product_id_array.forEach(id => {
            const transferAmountDiv = '#transfer_amount_' + id
            const transferAmount = $(transferAmountDiv).val()

            const transfererCommentDiv = '#transferer_comment' + id
            const transfererCommentAmount = $(transfererCommentDiv).val()

            if (transferAmount) {
              const order = {
                free_purchase_request_item_id: id,
                transfer_amount: transferAmount,
                transferer_comment: transfererCommentAmount,
              }
              this.inboundArr.push(order)
            }
          })
          if (this.inboundArr.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.submitLoading = true
          const formData = new FormData()
          formData.append('product_inbound_items', JSON.stringify(this.inboundArr))
          formData.append('hub_id', this.search_data.hub_id)
          formData.append('purchase_request_item_id', this.purchase_request_item_id)
          apiClient.post('api/free/hub/purchase-request/transfer', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$bvModal.hide('hub-transfer-free-product-modal')
                this.inboundArr = []
                this.$emit('getPurchaseRequest')
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.submitLoading = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    refreshComponentData(e) {
      this.hub = {}
      this.get_free_purchase_request_items = {}
      this.product_id_array = {}
    },
  },
}
</script>
<style>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
