<template>
  <div>
    <b-modal id="detail-free-product-modal" title="Detail Free Product" size="lg" hide-footer>
      <div class="modal-content bg-white rounded scrollarea-content">
        <template>
          <a-collapse v-for='(free_purchase_request_item, index) in free_purchase_request_items' accordion :key="free_purchase_request_item.id">
            <a-collapse-panel :key="index" :header="free_purchase_request_item.name">
              <div class="card card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="font-weight-bold">Free Product Name</label>
                      <p>{{ free_purchase_request_item.name }}</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="font-weight-bold">Product Quantity</label>
                      <p>{{ free_purchase_request_item.quantity }}</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="font-weight-bold">Inbound Quantity</label>
                      <p>{{ free_purchase_request_item.inbounded_quantity }}</p>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="font-weight-bold">Dispute Quantity</label>
                      <p>{{ free_purchase_request_item.dispute_quantity }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <h5>Transfer Details</h5>
              <table class="table table-sm table-bordered table-hover">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Hub</th>
                  <th>Transfer Amount</th>
                  <th>Dispute Amount</th>
                  <th>Status</th>
                  <th>Transferer Comment</th>
                  <th>Hub Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(free_purchase_request_item_transfer, i) in free_purchase_request_item.free_purchase_request_item_transfers' :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ free_purchase_request_item_transfer.hub.name }}</td>
                  <td>{{ free_purchase_request_item_transfer.transfer_amount }}</td>
                  <td>{{ free_purchase_request_item_transfer.dispute_amount }}</td>
                  <td>
                    <span class="badge badge-danger" v-if="free_purchase_request_item_transfer.transfer_status === 'Pending'">Pending</span>
                    <span class="badge badge-danger" v-if="free_purchase_request_item_transfer.transfer_status === 'Rejected'">Rejected</span>
                    <span class="badge badge-success" v-if="free_purchase_request_item_transfer.transfer_status === 'Accepted'">Accepted</span>
                    <span class="badge badge-warning" v-if="free_purchase_request_item_transfer.transfer_status === 'Partial'">Partial</span>
                  </td>
                  <td>{{ free_purchase_request_item_transfer.transferer_comment ? free_purchase_request_item_transfer.transferer_comment : 'No Comment' }}</td>
                  <td>{{ free_purchase_request_item_transfer.hub_comment ? free_purchase_request_item_transfer.hub_comment : 'No Comment' }}</td>
                </tr>
                </tbody>
              </table>
              <div class="table-responsive">
                <h5 class="text-center text-secondary" v-if="free_purchase_request_item.free_purchase_request_item_transfers && free_purchase_request_item.free_purchase_request_item_transfers.length === 0">
                  <Empty :image="simpleImage"></Empty>
                </h5>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </template>
        <div class="table-responsive">
          <h5 class="text-center text-secondary" v-if="free_purchase_request_items.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'

export default {
  components: { Empty },
  props: ['free_purchase_request_items'],
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  methods: {},
}
</script>
<style>

</style>
